body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  background: url('assets/Inclassificabili_Tier_BackgroundTavola-da-disegno-1-season-2.jpg');
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
.logo {
  background: url('assets/Logo_Inclassificabili-560x340.png');
  width: 280px; // 560px original
  height: 170px; // 400px original
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.app {
  // margin: auto;
  width: 70vw;
  padding: 20px 0;
  display: flex;
  flex-flow: column;
  justify-content: center;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 95vw;
    padding: 5px 0;
  }
}

.container {
  border: solid 2px black;
  background-color: black;
}

.default-container {
  border: solid 2px black;
  margin-top: 20px;
  background-color: #232323;
  min-height: 100px;

  &__content {
    display: flex;
    width: 100%;
    min-height: 105px;
    overflow-y: auto;

    &--dragging {
      background-color: #1d1d1d;
    }
  }
}

.row {
  display: flex;
  height: 100px;
  background-color: #1a1a1a;
  border: solid 1px black;
}

.row-settings {
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #1b1b1b;
  border-left: solid 2px black;
  padding: 10px;
  color: #989898;

  &__modal-button {
    padding: 0 10px;

    &:hover {
      cursor: pointer;
      color: white;
    }
  }

  &__controls {
    width: 30px;
  }

  &__icon {
    &:hover {
      cursor: pointer;
      color: white;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 120px;
  width: 10%;
  border-right: solid 1px black;
  text-align: center;
  font-weight: 500;

  &:focus {
    cursor: text;
  }
}

.content {
  background-color: #232323;
  display: flex;
  width: 100%;
  overflow-y: hidden;

  &--dragging {
    background-color: #1d1d1d;
  }
}

.item {
  display: inline-block;
  height: 100px;
  width: 150px;
  background-size: cover;
  margin: 0 0.25rem;

  &--dragging {
    opacity: 0.7;
  }
}

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #000000a8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &__close-button {
    outline: none;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    float: right;

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    background-color: white;
    color: black;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    outline: none;
    padding: 25px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    word-wrap: break-word;
    max-height: 850px;
    // overflow: auto;
    overflow: hidden;

    &:hover {
      overflow-y: overlay;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
    }

    @media (max-width: 600px) {
      max-width: 75vw;
      padding-bottom: 0;
    }
  }
}

.controls {
  margin-bottom: 10px;

  button:not(:last-child) {
    margin-right: 10px;
  }
}

.nav {
  margin-bottom: 10px;

  a:not(:last-child) {
    margin-right: 10px;
  }
}

.btn {
  font-family: inherit;
  min-height: 40px;
  min-width: 65px;
  padding: 0 20px;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: inline-flex;
  font-size: 17px;
  font-weight: 600;
  background-color: #16181c;
  color: white;
  border: none;
  border-radius: 6px;
  text-decoration: none;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #7b1515;
  }

  &:enabled:hover {
    background-color: black;
    cursor: pointer;
  }

  &--green {
    background-color: #019501;

    &:hover {
      background-color: green !important;
    }
  }

  &--red {
    background-color: #a50f0f;

    &:hover {
      background-color: #930d0d !important;
    }
  }

  &--blue {
    background-color: #3150de;

    &:hover {
      background-color: #2b47c5 !important;
    }
  }

  @media (max-width: 800px) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.create-form {
  &__images {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    background-color: #232323;
    border: solid 2px black;
  }

  &__image {
    max-width: 100px;
    max-height: 100px;
    opacity: 0.8;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  &__controls {
    margin-top: 20px;
  }
}

.import-form {
  &__textarea {
    font-size: 18px;
    min-height: 35vh;
    width: 100%;
    resize: vertical;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 10px 15px;
    font-family: inherit;
    line-height: 26px;
    border: solid 1px #cccccc;
    border-radius: 6px;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px #2684ff;
      border-color: #2684ff;
    }
  }

  &__info-box {
    margin-bottom: 15px;
    color: red;
    font-weight: bold;
  }
}

.row-settings-modal {
  padding: 10px;

  &__title {
    margin: 0px 0 25px 0;
    font-size: 26px;
    font-weight: 600;
  }

  &__controls {
    margin: 15px 0;
  }

  &__input {
    min-height: 50px;
    width: 100%;
    border: solid 1px #cccccc;
    border-radius: 6px;
    font-size: 21px;
    padding: 8px 15px;
    box-sizing: border-box;
    margin-bottom: 15px;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px #2684ff;
      border-color: #2684ff;
    }
  }

  &__button {
    width: 100%;
    font-family: inherit;
    min-height: 40px;
    min-width: 65px;
    padding: 0 20px;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: inline-flex;
    font-size: 17px;
    font-weight: 600;
    background-color: #16181c;
    color: white;
    border: none;
    border-radius: 6px;
    text-decoration: none;
    box-sizing: border-box;

    &:hover {
      background-color: black;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__colorpicker {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 25px 0;
  }

  @media (max-width: 600px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: white;
    // background-color: #000000;
  }
}
